import { i18n } from '../i18n';

/**
 * @deprecated Throw errors from ./errors.js
 */
export const ERRORS = {
    unknown: 'unknown',
    unauthorized: 'unauthorized',
    internal: 'internal',
};

const ERROR_MESSAGES = {
    unknown: i18n.errorUnknown,
    unauthorized: i18n.unauthorized,
};

/**
 * @deprecated Throw errors from ./errors.js
 */
export class CustomError extends Error {
    constructor(type, { message, originalError } = {}) {
        super(message || ERROR_MESSAGES[type] || ERROR_MESSAGES[ERROR_MESSAGES.unknown]);
        this.type = type || ERRORS.unknown;
        this.originalError = originalError;
        this.name = 'CustomError';
    }

    toString() {
        return [
            `Message: "${this.message}"`,
            `Type: "${this.type}"`,
            this.originalError ? `Original error: "${this.originalError}"` : null,
            `Stacktrace: ${this.stack}`,
        ]
            .filter(Boolean)
            .join('\n');
    }
}
