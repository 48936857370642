// @ts-check
export class FailedToFetchError extends Error {
    /**
     * @param {string} message
     * @param {any} error
     */
    constructor(message, error) {
        super(message || 'Failed to fetch');
        this.error = error;
    }
}

export class NotFoundError extends FailedToFetchError {
    /**
     * @param {string} message
     * @param {any} error
     */
    constructor(message, error) {
        super(message || 'Not found', error);
    }
}
