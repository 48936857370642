import LocalizedStrings from 'react-localization';

// KEYS MUST BE IN ENGLISH
//! IMPORTANT

export const i18n = new LocalizedStrings({
    es: {
        appLoadError: 'No ha sido posible cargar la aplicación',
        contactAdmin: 'Contacte con su administrador',

        duplicateNMRelationship: 'No es posible añadir el registro. Compruebe que no exista ya uno dado de alta con los mismos valores',
        resourceNotFound: 'No existe un recurso de tipo {0} con los parámetros proporcionados',

        activeRelations: 'No se han podido borrar {0} ya que se utilizan en otra sección de la aplicación',

        unauthorized: 'No tiene permisos para acceder a la aplicación',
        errorUnknown: 'Error desconocido',

        invalidData: 'Los datos enviados no han superado la fase de validación',
        notFound: 'No se han podido obtener los datos del servidor',
        urlParamsDonNotMatchBody: 'Los parámetros de la url no coinciden con su valor en el body',
        tokenNotFound: 'No se ha podido localizar el token',
        tokenInvalid: 'El token no es válido',
        conflict: 'Los datos han sido cambiados por otro usuario. Debe refrescar la página',
        alreadyExists: 'Ya existe un registro con el {0} seleccionado',

        notEnoughTimeAvailable: 'El tiempo solicitado es superior al disponible',

        resolvedRequestNoModifiable: 'Una solicitud aprobada o rechazada no puede ser modificada',

        datesOverlap: 'No ha sido posible guardar el registro ya que existen solapes entre fechas. Revise los registros existentes.',
        networkError: 'Error de red. Compruebe que dispone de acceso a internet o consulte con su administrador.',
        expirityDateReached: 'No es posible introducir registros ya que el período temporal está cerrado',

        timeout: 'El servidor tardó demasiado en responder',
    },
    en: {
        appLoadError: "Can't load the app",
        contactAdmin: 'Get in contact with your administrador',

        duplicateNMRelationship: 'Record could not be added. Check there are no previous records with the same values',
        resourceNotFound: 'Could not find a resource of type {0} with the given parameters',

        activeRelations: 'Some {0} could not be deleted as they are used in other sections of the application',

        unauthorized: "You don't have permissions to access the application",
        errorUnknown: 'Unknown error',

        invalidData: 'The submitted data did not pass the validation step',
        notFound: 'No data found at the server',
        urlParamsDonNotMatchBody: 'Url parameters do not match body data',
        tokenNotFound: 'Token not found',
        tokenInvalid: 'Invalid token',
        conflict: 'Data changed by another user. Refresh the page.',
        alreadyExists: 'A record with the selected {0} already exists',

        notEnoughTimeAvailable: 'Requested time is bigger than available',

        resolvedRequestNoModifiable: 'An approved or rejected requets cannot be modified',

        datesOverlap: 'Could not save the record due to a date overlap. Check the existing records.',
        networkError: 'Network error. Check you internet connection or contact with your admin.',
        expirityDateReached: 'Registry could not be saved due to the temporal period being closed',

        timeout: 'The server took too long to response',
    },
});
